
import { Component, Vue } from "vue-property-decorator";
import { ICentreData } from "@/api/types";
import { getCentres } from "@/api/centres";

@Component({
  name: "Centres",
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  //#region Datas
  private list: ICentreData[] = [];
  private listLoading = true;
  private listQuery = {
    criterias: "",
  };
  //#endregion

  //#region Lifecycle hooks
  created() {
    this.getList();
  }
  //#endregion

  //#region Methods
  // Récupération de la liste des centres
  private async getList() {
    this.listLoading = true;
    try {
      const { data } = await getCentres(this.listQuery);
      this.list = data.items;
    } catch (error) {
    } finally {
      this.listLoading = false;
    }
  }
  //#endregion
}
